import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import LogoBranco from "../../assets/Logo-Branco.png";
import { HiMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [menu, setMenu] = useState("hidden");
  const [openMenu, setOpenMenu] = useState(false);

  const { t, i18n } = useTranslation();

  const lngs = {
    pt: { nativeName: "PT" },
    en: { nativeName: "EN" },
  };

  const navigate = useNavigate();

  const loginCheck = () => {
    navigate("/login");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <>
      <div className="lg:h-[8vh] lg:block hidden w-full bg-transparent h-[10vh]"></div>

      <nav className="items-center lg:h-20 z-20 text-primary lg:bg-white rounded-none w-full lg:absolute fixed top-0 lg:my-0 my-3">
        <div className="relative justify-between items-center flex h-full mx-3 py-1 px-5 lg:mx-10 xl:mx-[12.5vw] bg-white lg:rounded-none rounded-[5rem] shadow-navbar lg:shadow-none">
          <div className="align-center flex items-center">
            <Link to="/" className="lg:mr-16">
              <img alt="Fortimy Logo" className="p-0 m-0 w-[6rem] lg:w-[8rem]" src={Logo} />
            </Link>
            <Link
              to="/businesses"
              className="lg:block hidden md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold duration-100 ease-in-out transition-all relative hover:border-b-2 hover:border-primary"
            >
              {t("Navbar.Businesses")}
            </Link>
            <Link
              to="/requests"
              className="lg:block hidden md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold duration-100 ease-in-out transition-all relative hover:border-b-2 hover:border-primary"
            >
              {t("Navbar.requests")}
            </Link>
            <Link
              to="/create-request"
              className="lg:block hidden md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold duration-100 ease-in-out transition-all relative hover:border-b-2 hover:border-primary"
            >
              {t("Navbar.CreateRequest")}
            </Link>
            <Link
              to="/pricing"
              className="lg:block hidden md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold duration-100 ease-in-out transition-all relative hover:border-b-2 hover:border-primary"
            >
              {t("Navbar.join")}
            </Link>
            <Link
              to="/faq"
              className="lg:block hidden md:mx-1 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold duration-100 ease-in-out transition-all relative hover:border-b-2 hover:border-primary"
            >
              {t("Navbar.Faq")}
            </Link>
          </div>
          
          <button
            onClick={() => {
              setOpenMenu(!openMenu)
              // setMenu(
              //   "absolute bg-primary w-screen h-[100vh] -top-3 left-0 z-50"
              // );
            }}
            className="text-4xl my-auto lg:hidden"
          >
            <HiMenu />
          </button>

          { openMenu &&
            <div className={`${openMenu ? 'opacity-100 lg:opacity-0' : 'opacity-0' } shadow-custom transition-all duration-200 ease-in-out flex flex-col items-center justify-center gap-5 absolute -z-10 left-0 top-5 text-primary bg-white rounded-xl pt-14 pb-5 h-fit w-full`}>
              <Link
                to="/login"
                className="uppercase font-bold"
                onClick={() => setOpenMenu(false)}
              >
                {t("Navbar.login")}
              </Link>
              <Link
                to="/businesses"
                className="uppercase font-bold"
                onClick={() => setOpenMenu(false)}
              >
                {t("Navbar.Businesses")}
              </Link>
              <Link
                to="/requests"
                className="uppercase font-bold"
                onClick={() => setOpenMenu(false)}
              >
                {t("Navbar.requests")}
              </Link>
              <Link
                to="/create-request"
                className="uppercase font-bold"
                onClick={() => setOpenMenu(false)}
              >
                {t("Navbar.CreateRequest")}
              </Link>
              <Link
                to="/pricing"
                className="uppercase font-bold"
                onClick={() => setOpenMenu(false)}
              >
                {t("Navbar.join")}
              </Link>
              <Link
                to="/faq"
                className="uppercase font-bold"
                onClick={() => setOpenMenu(false)}
              >
                {t("Navbar.Faq")}
              </Link>
            </div>
          }
          
          <div className="lg:relative hidden md:w-auto h-auto md:top-0 left-0 md:px-0 px-5 lg:flex justify-between">
            <div className="flex justify-center items-center">
              {Object.keys(lngs).map((lng) =>
                i18n.resolvedLanguage !== lng ? (
                  <button
                    type="submit"
                    key={lng}
                    className="text-lg mx-2 text-primary flex items-center"
                    onClick={() => changeLanguage(lng)}
                  >
                    <MdLanguage />
                    {lngs[i18n.resolvedLanguage].nativeName}
                  </button>
                ) : null
              )}
            </div>
            <div>
              <button
                onClick={loginCheck}
                className="px-3 py-2 md:bg-secondary bg-white font-bold shadow-xl mx-2 text-primary rounded-full"
              >
                {t('Navbar.login')}
              </button>
              <Link
                className="px-2 md:px-2 lg:px-3 py-2 text-white bg-sixth font-bold shadow-xl rounded-full cursor-not-allowed"
              >
                {t("Navbar.RequestDemo")}
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/*Scroll*/}
      <nav className="items-center h-16 fixed z-10 top-0 justify-center w-full text-white bg-none mt-2 hidden lg:block">
        <div className="flex h-full justify-between items-center mx-auto bg-primary md:px-5 lg:px-5 md:w-[98vw] lg:w-[96vw] xl:w-[85vw] xl:px-20 rounded-[5rem] shadow-2xl">
          <div className="align-center flex items-center">
            <Link to="/" className="md:mr-2 lg:mr-16">
              <img alt="Fortimy Logo" className="p-0 m-0 w-[6rem] lg:w-[8rem]" src={LogoBranco} />
            </Link>
            <Link to="/businesses" className="md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold">
              {t("Navbar.Businesses")}
            </Link>
            <Link to="/requests" className="md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold">
              {t("Navbar.requests")}
            </Link>
            <Link to="/create-request" className="md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold">
              {t("Navbar.CreateRequest")}
            </Link>
            <Link to="/pricing" className="md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold">
              {t("Navbar.join")}
            </Link>
            <Link to="/faq" className="md:mx-2 2xl:mx-4 text-sm xl:text-base 2xl:text-lg font-bold">
              {t("Navbar.Faq")}
            </Link>
          </div>
          <div className="flex">
            <div className="flex">
              {Object.keys(lngs).map((lng) =>
                i18n.resolvedLanguage !== lng ? (
                  <button
                    type="submit"
                    key={lng}
                    className="text-lg text-white flex items-center"
                    onClick={() => changeLanguage(lng)}
                  >
                    <MdLanguage className="mx-1" />
                    {lngs[i18n.resolvedLanguage].nativeName}
                  </button>
                ) : null
              )}
            </div>
            <button
              onClick={loginCheck}
              className="px-2 lg:px-3 py-1 rounded-full bg-secondary font-bold shadow-xl mx-1 lg:mx-2 text-primary"
            >
              {t('Navbar.login')}
            </button>
            <Link
              //style={{ border: "1px solid white" }}
              //to="/request-demo"
              className="md:px-2 lg:px-3 py-1 rounded-full text-white bg-sixth font-bold shadow-xl cursor-not-allowed"
            >
              {t("Navbar.RequestDemo")}
            </Link>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
};

export default Navbar;
