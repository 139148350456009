import React, { useEffect, useRef, useState } from "react";
import SearchForm from "../SearchForm/index.js";
import LocationIcon from "../../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { TbHandClick } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { useAlert } from "../../../contexts/AlertContext.js";

import RequestInfo from "../../Modals/RequestInfo.js";
import ShowAmenities from "../../Modals/ShowAmenities.js";
import CreateHighlights from "../../Modals/CreateHighlights";
import useAuthContext from "../../Config/AuthContext.js";

import Modal from "../../Modals/Modal";

import ArchiveRequestModal from "../../Modals/ArchiveRequestModal.js";

import axios from "../../Config/axios.js";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { currentLanguage } from "../../Config/i18n.js";
import RequestCard from "./RequestCard.js";
import Pagination from "../../UI/Pagination.js";
import { useParams } from 'react-router-dom';

const Active = () => {
    const {
    userRequests,
    archiveRequests,
    checkUserAuth
    } = useAuthContext();
    const { toggleLoader, triggerPopup } = useAlert();

    const { t } = useTranslation();
    const { id } = useParams();
    const isMounted = useRef(false);

    const [requests, setRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

	const [requestDetailsModal, setRequestDetailsModal] = useState(false);
	const [requestAmenitiesModal, setRequestAmenitiesModal] = useState(false);
	const [requestReportModal, setRequestReportModal] = useState(false);

	const [selectedRequest, setSelectedRequest] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

    const [createHighlightsModal, setCreateHighlightsModal] = useState("hidden");

    const [step, setStep] = useState(1);

    const [archiveRequestModal, setArchiveRequestModal] = useState(null);
    const [unarchiveRequestModal, setUnarchiveRequestModal] = useState(null);

    const [selectForHighlightRequest, setSelectForHighlightRequest] =
    useState(null);

    const [fieldRequired, setFieldRequired] = useState(false);

    const [showArchiveRequestModal, setShowArchiveRequestModal] = useState(false);
    const [selectedRequestToArchive, setSelectedRequestToArchive] =
    useState(null);

    const [isSubmiting, setIsSubmiting] = useState(false);

    const searchRequests = (data) => {
        setIsLoading(true);
        Object.keys(data).forEach((key) => {
            if (data[key] == null || data[key] === "") {
            delete data[key];
            }
        });

        data.archived = 0;

        userRequests({ ...data }).then((response) => {
            if (typeof response != "undefined") {
                if ((response.data.data)?.length > 0) {
                    setRequests(response.data);
                    setIsLoading(false);
                } else {
                    setRequests([]);
                    setIsLoading(false);
                }
            }
        });
    };

    const hideModalHighlights = () => {
        setCreateHighlightsModal("hidden");
    };

    const archiveRequestFunction = async (id) => {
        await archiveRequests(id);
    };

    const handlePageChange = (page) => {
		searchRequests({page: page})
		setCurrentPage(page)
	}

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (!isVisible) {
            setIsVisible(scrollTop > 100);
        }
    };

    const handleArchiveRequestModal = (value, requestId = null) => {
        setSelectedRequestToArchive(requestId);
        setShowArchiveRequestModal((current) => value);
    };

    const archiveRequest = () => {
        if (selectedRequestToArchive != null) {
            setIsSubmiting(true);
            axios
            .put(`/api/toggle-archive-request/${selectedRequestToArchive}`)
            .then((response) => {
                setShowArchiveRequestModal(false);
                searchRequests({});
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsSubmiting(false);
                checkUserAuth()
            });
        }
    };

    useEffect(() => {
        setUnarchiveRequestModal(null);
        if (isMounted.current) {
            if (id && requests) {
                // eslint-disable-next-line array-callback-return
                (requests?.data)?.map((request) => {
                    if (request.id == id) {
                        setSelectedRequest(request)
                        setRequestDetailsModal(true)
                    }
                })
            }
        } else {
            isMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requests]);

    useEffect(() => {
        window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (fieldRequired) {
            triggerPopup(t("Dashboard.my_requests.fields_required"));
            setTimeout(() => {
            setFieldRequired(false);
            clearTimeout();
            }, 2500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldRequired]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
    <>
        <Modal isOpen={showArchiveRequestModal}>
            {showArchiveRequestModal && (
            <ArchiveRequestModal
                title={t('Modals.archive_request_title')}
                description={t('Modals.archive_request_subtitle')}
                onCancel={() => {
                handleArchiveRequestModal(false);
                }}
                onConfirm={() => {
                archiveRequest();
                }}
                isLoading={isSubmiting}
            />
            )}
        </Modal>
        <Modal isOpen={requestDetailsModal}>
            <RequestInfo
                request={selectedRequest}
                onClose={() => { setRequestDetailsModal(false) }}
                onViewAmenities={() => { setRequestAmenitiesModal(true) }}
                onReport={() => { setRequestReportModal(true) }}
            />
        </Modal>
        <Modal isOpen={requestAmenitiesModal}>
            <ShowAmenities
                showAmenitiesModal={() => { setRequestAmenitiesModal(false) }}
                data={selectedRequest}
            />
        </Modal>
        <div className={createHighlightsModal}>
            <CreateHighlights
                modalHighlights={hideModalHighlights}
                requestId={selectForHighlightRequest}
            />
        </div>

        <div className="flex flex-col pb-20">
            <SearchForm searchRequests={searchRequests} />
        </div>
        {(requests?.data)?.length > 0 ? (
            <>
                <div className="flex flex-col w-full">
                    {(requests?.data)?.map((request, index) =>
                        request.archived === 0 && (
                            <RequestCard
                                key={index}
                                request={request}
                                onDetails={() => { setSelectedRequest(request); setRequestDetailsModal(true) }}
                                onViewAmenities={() => { setSelectedRequest(request); setRequestAmenitiesModal(true) }}
                                onArchive={() => { handleArchiveRequestModal(true, request.id) }}
                            />
                        )
                    )}
                </div>
                <Pagination
					itemCount={requests.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>
            </>
        ) : (
            <div className="w-full flex justify-center text-primary text-3xl font-bold">
                {t("Dashboard.my_requests.no_active_requests")}
            </div>
        )}
    </>
    );
};

export default Active;
