import React, { useEffect, useState } from "react";
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";
import { MANAGE_USERS_FILTERS } from "../../../constants/global";
import UserCard from "./UserCard";
import Modal from "../../Modals/Modal";
import ActionConfirmationModal from "../../Modals/ActionConfirmationModal";
import { useTranslation } from "react-i18next";
import Pagination from "../../UI/Pagination";

const Users = () => {
	const { toggleLoader, triggerPopup } = useAlert();
	const { t } = useTranslation();

	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState(MANAGE_USERS_FILTERS.ALL);
	const [search, setSearch] = useState("");
	const [userData, setUserData] = useState({});
	const [disable, setDisable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const getUsers = async () => {
		setIsLoading(true);
		const response = await axios.get("/api/users", {
			params: {
				per_page: 10,
				page: currentPage,
				search: search,
				filter: filter,
			},
		});
		if (response.status === 200) {
			setUsers(response.data);
			setIsLoading(false);
		}
	};

	const updateUser = async (formData) => {
		try {
			setIsLoading(true);
			const response = await axios.put(`/api/users/${formData.id}`, formData);
			if (response.status === 204) {
				triggerPopup(t('Popups.changes_saved'));
				getUsers();
			}
		} catch (error) {
			setIsLoading(false);
			triggerPopup(t('Popups.something_went_wrong'));
			return error.response;
		}
		setIsLoading(false);
	};
	
	const verifyUser = async (id) => {
		setDisable(true);
		setIsLoading(true);
		try {
			const response = await axios.put(`/api/users/${id}/verify`, {
				verified: 1,
			});
			if (response.status === 200) {
				setUserData(response.data);
				setDisable(false);
				setIsLoading(false);
				triggerPopup(t('Popups.email_sent_to_user'));
			}
		} catch (error) {
			setDisable(false);
			setIsLoading(false);
			triggerPopup(t('Popups.something_went_wrong'));
		}
	};

	const triggerDeleteModal = (e) => {
		e.stopPropagation();
		setOpenModal(true);
	}

	// const deleteUser = async (id) => {
	// 	setIsLoading(true);
	// 	setOpenDeleteModal(false);
	// 	try {
	// 		const response = await axios.delete(`/api/users/${id}`, {
	// 			data: {
	// 				id: id
	// 			}
	// 		});
	// 		if (response.status === 204) {
	// 			triggerPopup('User deleted successfully!');
	// 			getUsers();
	// 		}
	// 		setIsLoading(false);
	// 	} catch (error) {
	// 		if (error.response.status === 422) {
	// 			triggerPopup(error.response.data.message);
	// 		}
	// 		setIsLoading(false);
	// 	}
		
	// }

	const blockUser = async (user) => {
		setIsLoading(true);
		setOpenModal(false);
		try {
			const response = await axios.put(`/api/block-user/${user.id}`);
			if (response.status === 200) {
				triggerPopup(response.data === 'blocked' ? t('Popups.blocked_user') : t('Popups.unblocked_user'));
				getUsers();
			}
			setIsLoading(false);
		} catch (error) {
			if (error.response.status === 422) {
				triggerPopup(t('Popups.something_went_wrong'));
			}
			setIsLoading(false);
		}
		
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
	}

	useEffect(() => {
		setUsers(prevUsers => ({
			...prevUsers,
			data: (prevUsers?.data)?.map((user) => (user.id === userData.id ? userData : user))
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		getUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, currentPage]);

	useEffect(() => {
		toggleLoader(isLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<>
		<Modal isOpen={ openModal }>
			<ActionConfirmationModal
				title={selectedUser?.blocked ? t('Modals.unblock_user_title') : t('Modals.block_user_title')}
				onCancel={ () => { setOpenModal(false) } }
				onConfirm={ () => { blockUser(selectedUser) } }
			/>
		</Modal>

		<div className="flex flex-col w-full gap-y-5">
			<div className="w-fit border-2 rounded-lg grid grid-cols-4 mt-6 md:text-lg text-base">
				<button
					onClick={() => setFilter(MANAGE_USERS_FILTERS.ALL)}
					className={`${
						filter === MANAGE_USERS_FILTERS.ALL ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.all')}
				</button>
				<button
					onClick={() => setFilter(MANAGE_USERS_FILTERS.ACTIVE)}
					className={`${
						filter === MANAGE_USERS_FILTERS.ACTIVE ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.active')}
				</button>
				<button
					onClick={() => setFilter(MANAGE_USERS_FILTERS.PENDING)}
					className={`${
						filter === MANAGE_USERS_FILTERS.PENDING ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.pending')}
				</button>
				<button
					onClick={() => setFilter(MANAGE_USERS_FILTERS.BLOCKED)}
					className={`${
						filter === MANAGE_USERS_FILTERS.BLOCKED ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.blocked')}
				</button>
			</div>
			<div className="grid grid-cols-4 xl:gap-5 md:gap-2 text-xl mb-5">
				<div className="col-span-3">
					<input
						type="text"
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						placeholder={t('Dashboard.admin.search_for_a_user')}
						className="border-2 rounded-md h-full w-full placeholder:text-black p-2 text-xl"
					/>
				</div>
				<button
					onClick={() => getUsers()}
					type="button"
					className="col-span-1 w-full bg-primary font-bold text-white p-2 rounded-md shadow-md"
				>
					{t('Dashboard.admin.search')}
				</button>
			</div>

			{(users?.data)?.map((user, index) => (
				<UserCard
					key={index}
					user={user}
					disable={disable}
					onVerify={ () => {verifyUser(user.id)} }
					onEdit={ (formData) => updateUser(formData) }
					onDelete={ (e) => {triggerDeleteModal(e); setSelectedUser(user)} }
					onBlock={ (e) => { 
						e.stopPropagation();
						setOpenModal(true);
						setSelectedUser(user);
					}}
				/>
			))}

			<Pagination
				itemCount={users.last_page}
				itemsPerPage={currentPage}
				onPageChange={(page) => handlePageChange(page)}
			/>

			{ !isLoading && (users?.data)?.length === 0 && (
                <p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.admin.no_results')}</p>
            )}
		</div>
		</>
	);
};

export default Users;
