import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";
import ChatWindow from "./ChatWindow";
import ChatCard from "./ChatCard";
import { t } from "i18next";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { useParams } from 'react-router-dom';
import RequestInfo from "../../Modals/RequestInfo";
import Modal from "../../Modals/Modal";
import ReportRequest from "../../Modals/ReportRequest";
import ShowAmenities from "../../Modals/ShowAmenities";
import Pagination from "../../UI/Pagination";

const Messages = () => {
	const { auth } = useAuth();
	const { toggleLoader } = useAlert();
	const { id } = useParams();

	const  [isLoading, setIsLoading] = useState(true);

	const [chats, _setChats] = useState([]);
	const [selectedChat, _setSelectedChat] = useState(null);
	const [messages, _setMessages] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(null);

	const selectedChatRef = React.useRef(selectedChat);
	const messagesRef = React.useRef(messages);
	const chatsRef = React.useRef(chats);

	const setSelectedChat = (data) => {
		selectedChatRef.current = data;
		_setSelectedChat(data);
	};
	const setMessages = (data) => {
		messagesRef.current = data;
		_setMessages(data);
	};
	const setChats = (data) => {
		chatsRef.current = data;
		_setChats(data);
	};

	const [requestDetailsModal, setRequestDetailsModal] = useState(false);
	const [requestAmenitiesModal, setRequestAmenitiesModal] = useState(false);
	const [requestReportModal, setRequestReportModal] = useState(false);

	const [selectedRequest, setSelectedRequest] = useState([]);

	const getMessages = async (chat) => {
		if (chat === null) {
			return;
		}

		setMessages([]);

		await axios
			.get("/api/chats/get-messages", {
				params: {
					chat_id: chat.id,
				},
			})
			.then((response) => {
				setMessages(response.data.messages);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getChats = async () => {
		setIsLoading(true);
		await axios
			.get("/api/chats", {
				params: {
					per_page: 10,
					page: currentPage,
					request_id: id ?? null
				},
			})
			.then((response) => {
				const tempChats = []
				if (response.data?.data) {
					(response.data?.data).forEach(chat => {
						//TODO: Não está a mostrar chats porque eles não têm mensagens
						if (chat.has_messages || chat.request_id == id) {
							tempChats.push(chat)
						}
					})
				}
				setChats(tempChats)
				setLastPage(response.data?.last_page)
				declareChannels(response.data?.data)
				if (id) {
					response.data?.data.map((data) => {
						if (data.request_id == id && (data.owner?.id == auth.id || data.buyer?.id == auth.id)) {
							setSelectedChat(data);	
						}
					});
				}
				// console.log(tempChats)
				// console.log(chats)
			})
			.catch((error) => {
				console.log(error);
			}).finally(() => {
				setIsLoading(false);
			});

	};

	function declareChannels(dataChats) {
		for (const chat of dataChats) {
			window.Echo.channel(`my-channel-${chat.id}`).listen(".my-event", (data) => {
				if (selectedChatRef?.current?.id === chat.id) {
					setMessages([...messagesRef.current, data]);
				}
				const updatedChats = chatsRef.current.map((chat) => 
					(chat.id == data.chat_id 
						? {...chat, 
							blocked: chat.blocked,
							buyer: chat.buyer,
							buyer_id: chat.buyer_id,
							buyer_image: chat.buyer_image,
							owner_company_id: chat.owner_company_id,
							buyer_company_id: chat.buyer_company_id,
							id: chat.id,
							latest_message_creator: chat.latest_message_creator,
							location: chat.location,
							owner: chat.owner,
							owner_id: chat.owner_id,
							owner_image: chat.owner_image,
							property: chat.property,
							property_image: chat.property_image,
							request_id: chat.request_id,
							updated_at_date: data.updated_at_date,
							updated_at_hour: data.updated_at_hour,
							has_messages: 1,
							unread_messages: (
								chat.latest_message_creator != auth.id
								&& selectedChatRef?.current?.id == chat.id)
									? 0
									: chat.unread_messages + 1
						} : chat
					)
					)
				setChats(updatedChats.sort(sortChats));
			});
		}
	}

	const sortChats = (a, b) => {
		const dateA = new Date(a.updated_at_date.split('/').reverse().join('-') + 'T' + a.updated_at_hour + ':00');
		const dateB = new Date(b.updated_at_date.split('/').reverse().join('-') + 'T' + b.updated_at_hour + ':00');
		
		const dateComparison = dateB - dateA;
		
		if (dateComparison === 0) {
			return b.updated_at_hour.localeCompare(a.updated_at_hour);
		}

		return dateComparison;
	}

	const handleChatSelection = async (selectedChat) => {
		setSelectedChat(selectedChat);
		if (selectedChat.unread_messages) {
			const response = await axios.post('api/chats/mark-as-read', selectedChat);
			if(response.status === 204)
			{
				setChats(chatsRef.current.map((chat) =>
					(chat.id === selectedChat.id
						? {...selectedChat, unread_messages: 0}
						: chat
					)
				));				
			}
		}
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
	}

	const openDetailsModal = async (requestId) => {
		const response = await axios.post("/api/get-filtered-requests", { id: requestId });
		if (response.status === 200 && response.data?.data?.length > 0) {
			setSelectedRequest(response.data?.data[0]);
			setRequestDetailsModal(true);
		}
	}

	useEffect(() => {
		window.Pusher = Pusher;
		window.Echo = new Echo({
			broadcaster: "pusher",
			key: `${process.env.REACT_APP_PUSHER_APP_KEY}`,
			cluster: "eu",
			forceTLS: true,
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getChats();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		getMessages(selectedChat);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedChat]);

	useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<>
			<section>
				<div className="flex flex-col">
					<div className="w-full flex justify-between items-center md:py-5 border-b-2 mb-5 md:mb-10">
						<h1 className="text-2xl md:text-3xl font-bold text-black">Messages</h1>
					</div>
				</div>

				<Modal isOpen={requestDetailsModal}>
					<RequestInfo
						request={selectedRequest}
						onClose={() => { setRequestDetailsModal(false) }}
						onViewAmenities={() => { setRequestAmenitiesModal(true) }}
						onReport={() => { setRequestReportModal(true) }}
						hasContactInfo
					/>
				</Modal>
				<Modal isOpen={requestAmenitiesModal}>
					<ShowAmenities
						showAmenitiesModal={() => { setRequestAmenitiesModal(false) }}
						data={selectedRequest}
					/>
				</Modal>
				<Modal isOpen={requestReportModal}>
					<ReportRequest
						request={selectedRequest}
						onClose={() => { setRequestReportModal(false) }}
					/>
				</Modal>

				<div className={`${(!isLoading && chats.length > 0) ? 'md:grid grid-cols-3 flex xl:gap-10 md:gap-5' : 'hidden'}`}>
					<div className="hidden md:col-span-1 w-full md:flex flex-col bg-white shadow-custom rounded-3xl h-screen overflow-y-scroll no-scrollbar">
						{chats.map((chat, index) => (
							<ChatCard key={index} data={chat} authUser={auth} onClick={() => handleChatSelection(chat)} isSelected={selectedChat?.id} />
						))}
						<div className="mt-auto mb-5">
							<Pagination
								itemCount={lastPage}
								itemsPerPage={currentPage}
								onPageChange={(page) => handlePageChange(page)}
							/>
						</div>
					</div>
					{/*
					{selectedChat == null && 
						<div className="md:col-span-1 w-full flex md:hidden flex-col bg-white shadow-custom rounded-3xl h-screen overflow-y-scroll no-scrollbar">
							{chats.map((chat, index) => (
								<ChatCard key={index} data={chat} authUser={auth} onClick={() => handleChatSelection(chat)} isSelected={selectedChat?.id} />
							))}

							<Pagination
								itemCount={lastPage}
								itemsPerPage={currentPage}
								onPageChange={(page) => handlePageChange(page)}
							/>
						</div>
					}
					*/}
					{selectedChat != null &&
						<ChatWindow
							chat={selectedChat}
							authUser={auth}
							render_messages={messages}
							returnToChats={() => { setSelectedChat(null) }}
							openDetails={(requestId) => { openDetailsModal(requestId) }}
						/>
					}
				</div>
				{ !isLoading && chats.length === 0 && (
                    <p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.messages.no_chats')}</p>
                )}
			</section>
		</>
	);
};

export default Messages;
