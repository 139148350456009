import React, { useState, useEffect, useRef } from "react";
import SearchForm from "../Dashboard/SearchForm/index.js";
import { useAlert } from "../../contexts/AlertContext.js";
import axios from "../Config/axios.js";
import RequestCard from "../Dashboard/Requests/RequestCard.js";
import { t } from "i18next";
import Pagination from "../UI/Pagination.js";
import { MANAGE_REQUESTS_FILTERS } from "../../constants/global.js";
import useAuthContext from "../../components/Config/AuthContext";
import useAuth from "../../hooks/useAuth";

const Requests = () => {
    const { toggleLoader } = useAlert();
	const { checkUserAuth } = useAuthContext();
	const { auth } = useAuth();

	const isMounted = useRef(false);

	const [requests, setRequests] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [searchParams, setSearchParams] = useState({ per_page: perPage, page: currentPage, filter: MANAGE_REQUESTS_FILTERS.UNARCHIVED, showVerified: 1 });
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	
	const crsf = async () => axios.get("/sanctum/csrf-cookie");

	const getRequests = async () => {
		setIsLoading(true);
		await crsf();
		const response = await axios.post("/api/guest/get-filtered-requests", { ...searchParams });
		if (response.status === 200) {
			setRequests(response.data);
			setIsLoading(false);
		}
	};

	const handleScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (!isVisible) {
			setIsVisible(scrollTop > 100);
		}
	};

	const handlePageChange = (page) => {
		setSearchParams({...searchParams, page: page})
		setCurrentPage(page)
	}

	useEffect(() => {
		if (isMounted.current) {
			getRequests(currentPage);
		}
		else {
			isMounted.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		if (typeof auth) {
			checkUserAuth();
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	return (
		<>
            <SearchForm searchRequests={ (data) => { setSearchParams({...data, per_page: perPage, page: currentPage, filter: MANAGE_REQUESTS_FILTERS.UNARCHIVED, showVerified: 1 }) }} hasSort hasAdditionalFields isGuest />

			<div className="flex flex-col w-full mt-10 md:mt-20">
				{(requests?.data)?.length > 0 &&
					(requests?.data)?.map((request, index) => (
						<RequestCard
							key={index}
							request={request}
							onDetails={() => {  }}
							onViewAmenities={() => {  }}
							onReport={() => {  }}
                            unauth
						/>
					))
				}

				<Pagination
					itemCount={requests.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>

			</div>
			{ !isLoading && (requests?.data)?.length === 0 && (
				<p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.admin.no_results')}</p>
			)}
		</>
	);
}

export default Requests;