import { createContext, useContext, useState } from "react";
import axios from "./axios";
import useAuth from "../../hooks/useAuth";
import { APP_PATHS, MY_REQUESTS_TABS_PATH } from "../../constants/global";

const AuthContext = createContext({});

// TODO remover funções que NÃO estão relacionadas com autenticação e meter em outros contextos
export const AuthContexts = ({ children }) => {
  const { auth, setAuth, setLocation } = useAuth();

  const [currentUser, setCurrentUser] = useState(null);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(0);

  const crsf = () => axios.get("/sanctum/csrf-cookie");

  const getCurrentUser = async () => {
    const response = await axios.get("/api/user");
    // setCurrentUser(response.data);
    return response.data;
  };

  const login = async ({ ...data }) => {
    setErrors([]);
    await crsf();
    try {
      const response = await axios.post("/login", data);
      if (response.status === 204) {
        const response = await getCurrentUser();
        if (response.email_verified_at) {
          setAuth((current) => response);
          // setLocation(response?.has_requests ? APP_PATHS.ABOUT : MY_REQUESTS_TABS_PATH.CREATE);
          setTimeout(() => {
            setErrors([]);
          }, 400);
        } else {
          setErrors({ email: ["These credentials do not match our records."] });
          logout();
        }
      }
      return response;
    } catch (e) {
      if (e.response.status === 422 || e.response.status) {
        setErrors(e.response.data.errors);
        return e.response;
      }
    }
  };

  const submitRegistrationForm = async (formData) => {
    try {
      setErrors([]);
      await crsf();
      return await axios.post("/api/submit-registration-form", formData);
    } catch (e) {
      if (e.response.status === 422 || e.response.status) {
        setErrors(e.response.data.errors);
        return e.response;
      }
    }
  };

  const register = async (data) => {
    let resposta;
    await crsf();
    try {
      await axios.post("/register", data).then((response) => {
        getCurrentUser();
        resposta = response.data.id;
      });
      return resposta;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const registerBusiness = async (data) => {
    await crsf();
    try {
      let response = await axios.post("/api/companies", data);
      return response.data;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const forgotPassword = async ({ params }) => {
    try {
      await crsf();
      return await axios.post("/forgot-password", { ...params });
    } catch (error) {
      return error;
    }
  };

  const resetPassword = async ({
    token,
    email,
    password,
    passwordConfirmation,
  }) => {
    try {
      await crsf();
      return await axios.post("/reset-password", {
        token: token,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createPassword = async ({
    id_encrypt,
    password,
    passwordConfirmation,
  }) => {
    try {
      await crsf();
      return await axios.post("/create-password", {
        id_encrypt: id_encrypt,
        password: password,
        password_confirmation: passwordConfirmation,
      });
    } catch (error) {
      return error.response;
    }
  };

  const getAllRequests = async (loadedCount) => {
    await crsf();
    try {
      let response = await axios.get(
        `/api/requests?loadedCount=${loadedCount}`
      );
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const createRequest = async ({ ...data }) => {
    const dataToSubmit = {
      user_id: auth.id,
      first_name: data.firstName,
      last_name: data.lastName,
      nationality: data.nationality,
      country_code: data.country_code,
      phone: data.phoneNumber,
      show_phone: data.showPhone,
      show_email: data.showEmail,
      buyer_type_id: data.buyerTypeId,
      urgency_id: data.urgencyId,
      investment_type_id: data.investmentTypeId,
      request_type_id: data.requestTypeId,
      property_types: JSON.stringify(data.selectedPropertyTypes),
      zones: data.zone,
      price_until: data.priceUntil,
      area_from: data.areaFrom,
      condition_id: data.conditionId,
      typology_id: data.typologyId,
      bathroom_id: data.bathroomId,
      amenities: JSON.stringify(data.selectedAmenities),
      brief_description: data.brief,
      detailed_description: data.description,
    };

    await crsf();
    try {
      setErrors([]);
      return await axios.post("/api/requests", dataToSubmit);
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const searchRequest = async ({ ...data }) => {
    const {
      price_from,
      price_until,
      area_from,
      area_until,
      district,
      municipality,
      zone,
      property_type,
      request_type,
      id,
    } = data;

    let params = {};
    if (price_from !== "" && price_from)
      params.price_from = parseInt(price_from);
    if (price_until !== "" && price_until)
      params.price_until = parseInt(price_until);
    if (area_from !== "" && area_from) params.area_from = parseInt(area_from);
    if (area_until !== "" && area_until)
      params.area_until = parseInt(area_until);
    if (district !== "" && district) params.district = district;
    if (municipality !== "" && municipality) params.municipality = municipality;
    if (zone !== "" && zone) params.zone = zone;
    if (property_type !== "" && property_type)
      params.property_type = parseInt(property_type);
    if (request_type !== "" && request_type)
      params.request_type = parseInt(request_type);
    if (id !== "" && id) params.id = id;

    await crsf();
    try {
      console.log(params);
      let response = await axios.get("/api/search", { params });
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const userRequests = async (params = {}) => {
    await crsf();
    try {
      let response = await axios.post("/api/get-all-requests", { ...params });
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const userArchiveRequests = async () => {
    await crsf();
    try {
      let response = await axios.get("/api/user/archived-requests");
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const usersTeam = async () => {
    await crsf();
    try {
      let response = await axios.get("/api/company/users");
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const storeAddons = async (data) => {
    const { requests_number, top_highlights, colour_highlights, users_number } =
      data;
    let params = {};
    params.id = currentUser.id;
    params.requests = requests_number;
    params.top_highlights = top_highlights;
    params.colour_highlights = colour_highlights;
    params.users = users_number;
    await crsf();
    try {
      axios.put("/api/user/addons", params).then((response) => {
        console.log(response.data);
        setCurrentUser(response.data);
        window.location.reload();
      });
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const getUserCompany = async () => {
    await crsf();
    try {
      let response = await axios.get("/api/user-company");
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const removeUserFromCompany = async (user) => {
    await crsf();
    try {
      return await axios.put(`/api/companies/remove-user/${user}`);
    } catch (e) {
      console.log(e.response.data.errors);
    }
  };

  const getDuplicates = async () => {
    await crsf();
    try {
      let response = await axios.get("/api/duplicates");
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const archiveRequests = async (id) => {
    await crsf();
    try {
      axios.put("/api/toggle-archive-request", { id: id });
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const unarchiveRequests = async (id) => {
    await crsf();
    try {
      await axios.put(`/api/toggle-archive-request/${id}`);
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const settingsGeneralInfo = async (data) => {
    await crsf();
    try {
      await axios
        .put("/api/settings/general-info", data)
        .then((response) => console.log(response));
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const settingsLogin = async () => {
    await crsf();
    try {
      await axios.put("/api/settings/login");
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const settingsBilling = async (data) => {
    await crsf();
    try {
      await axios.put("/api/settings/billing", data);
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const uploadProfilePicture = async (picture) => {
    const data = new FormData();
    data.append("image", picture);
    await crsf();
    try {
      const response = await axios.post("/api/profile-pictures", data);
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const deleteRequest = async (id) => {
    try {
      const response = await axios.delete("/api/requests/" + id);
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const createTopHighlight = async (id) => {
    await crsf();
    try {
      let response = axios.put("/api/top-highlight-request", { id: id });
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };
  /*const deleteTopHighlight = async (id) => {
        // await crsf();
        try {
            axios.put('/api/top-unhighlight-request', { id: id });
        } catch (e) {
            if (e.response.status === 422) {
                setErrors(e.response.data.errors);
                console.log(e.response.data.errors);
            }
        }
    }*/
  const createColorHighlight = async (id) => {
    await crsf();
    try {
      let response = axios.put("/api/color-highlight-request", { id: id });
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };
  /*
    const deleteColorHighlight = async (id) => {
        await crsf();
        try {
            axios.put('/api/color-unhighlight-request', { id: id });
        } catch (e) {
            if (e.response.status === 422) {
                setErrors(e.response.data.errors);
                console.log(e.response.data.errors);
            }
        }
    }*/

  const getProperties = async () => {
    await crsf();
    try {
      let response = await axios.get("/api/properties");
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const getAmenities = async () => {
    await crsf();
    try {
      let response = await axios.get("/api/amenities");
      return response;
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors);
        console.log(e.response.data.errors);
      }
    }
  };

  const logout = () => {
    axios.post("/logout").then(() => {
      setAuth((current) => {});
      //window.location.href = "/login";
    });
  };

  const changeBackendLanguage = (lng) => {
    axios.post("/api/change-lang", { locale: lng });
  };

  const editUser = async (user, formData) => {
    await crsf();
    try {
      return await axios.post(`/api/users/${user}`, formData);
    } catch (e) {
      return e.response;
    }
  };

  const addUserToTeam = async (formData) => {
    await crsf();
    try {
      return await axios.post(`/api/users`, formData);
    } catch (e) {
      return e.response;
    }
  };

  const checkUserAuth = async () => {
    return getCurrentUser()
      .then(async (response) => {
        await setAuth((current) => response);
      })
      .catch((e) => {
        if (e.response.status == 503) {
          window.location.href = "/maintenance";
        }
      });
  };

  const getTypeOfRequests = async () => {
    await crsf();
    return axios.get("api/requestTypes");
  };

  const getLocations = async (data = {}) => {
    await crsf();
    return axios.get("api/locations", { params: data });
  };

  const getUrgencies = async () => {
    await crsf();
    return axios.get("api/urgencies");
  };

  const getBuyerTypes = async () => {
    await crsf();
    return axios.get("api/buyerTypes");
  };

  const getConditions = async () => {
    await crsf();
    return axios.get("api/conditions");
  };

  const getTypologies = async () => {
    await crsf();
    return axios.get("api/typologies");
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        errors,
        success,
         crsf,
        getCurrentUser,
        // getUserRoute,
        // getUserAdminRoute,
        login,
        submitRegistrationForm,
        register,
        registerBusiness,
        logout,
        forgotPassword,
        resetPassword,
        createPassword,
        usersTeam,
        createRequest,
        userRequests,
        deleteRequest,
        userArchiveRequests,
        getAllRequests,
        searchRequest,
        storeAddons,
        getUserCompany,
        archiveRequests,
        unarchiveRequests,
        removeUserFromCompany,
        settingsGeneralInfo,
        settingsLogin,
        settingsBilling,
        getDuplicates,
        uploadProfilePicture,
        createTopHighlight,
        createColorHighlight,
        getProperties,
        getAmenities,
        changeBackendLanguage,
        editUser,
        addUserToTeam,
        checkUserAuth,
        getTypeOfRequests,
        getLocations,
        getUrgencies,
        getBuyerTypes,
        getConditions,
        getTypologies,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuthContext() {
  return useContext(AuthContext);
}
