import React from "react";
import { API_PATHS, COMPANY_ROLES } from "../../../constants/global";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

const ListViewCard = ({ currentUser, member, isAdmin, onEdit, onDelete }) => {

	const { t } = useTranslation();

	return (
		<>
			{
				<div
					key={member.id}
					className={`${
						member.id === currentUser.id ? "bg-black/5 shadow-xl" : ""
					} flex w-full items-center justify-between px-4 md:px-8 border-b-2 py-5`}
				>
					<div className="flex items-center">
						<img
							src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${member.profile_image}`}
							className="w-10 h-10 md:w-14 md:h-14 mr-3 md:mr-5 rounded-full object-cover"
							alt="User Profile"
						/>
						<div className="flex flex-col md:flex-row md:items-center">
							<h1 className="font-bold md:text-3xl text-xl text-black mr-5">{member.first_name}</h1>
							<span className="text-black/50 text-sm md:text-base">
								{member.company_role_id === COMPANY_ROLES.ADMIN ? t('Dashboard.team.admin') : t('Dashboard.team.user')}
							</span>
						</div>
					</div>
					<div className="flex items-center">
						{(isAdmin || currentUser.id === member.id) && (
							<button onClick={onEdit} className="flex items-center text-primary md:mr-10">
								<p className="text-2xl mx-2">
									<AiOutlineEye />
								</p>
								<span className="hidden md:block">{t('Dashboard.team.view')}</span>
							</button>
						)}
						{isAdmin && member.company_role_id !== COMPANY_ROLES.ADMIN && (
							<button onClick={onDelete} className="flex items-center text-primary">
								<p className="text-xl">
									<BsTrashFill />
								</p>
							</button>
						)}
					</div>
				</div>
			}
		</>
	);
};

export default ListViewCard;
