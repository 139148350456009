import React, { useState, useRef, useCallback, useEffect } from 'react';
import SearchForm from '../SearchForm';
import FirstIcon from '../../../assets/Dashboard/Homepage/FirstIcon.png';
import { Link } from 'react-router-dom';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ViewEnquiries from '../../Modals/ViewEnquiries';
import CreateHighlights from '../../Modals/CreateHighlights';
import { useAlert } from '../../../contexts/AlertContext';
import useAuth from '../../../hooks/useAuth';
import axios from '../../Config/axios';
import Requests from './Requests';
import Inbox from './Inbox';
import Team from './Team';
import Plan from './Plan';
import Highlights from './Highlights';
import Duplicates from './Duplicates';
import Overview from './Overview';
import VideoSlider from '../Learning/VideoSlider';
import { useTranslation } from "react-i18next";
import DuplicatesBanner from './DuplicatesBanner';

const DashboardHomepage = () => {

    const { auth } = useAuth();
    const { toggleLoader } = useAlert();
    const { t } = useTranslation();

    const [enquiriesModal, setEnquiriesModal] = useState('hidden');
    const [createHighlightsModal, setCreateHighlightsModal] = useState('hidden');
    const [requests, setRequests] = useState([]);
    const [chats, setChats] = useState([]);
    const [team, setTeam] = useState([]);
    const [duplicates, setDuplicates] = useState([]);
    const [videos, setVideos] = useState([]);
    const [selectForHighlightRequest, setSelectForHighlightRequest] = useState(null);
    const [overview, setOverview] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [tab, setTab] = useState(0);

    const getRequests = async ( params = null ) => {
        setIsLoading(true);

        if (params) {
            Object?.keys(params)?.forEach((key) => {
                if (params[key] == null || params[key] === "") {
                delete params[key];
                }
            });
        }

        await axios.post("/api/get-all-requests", params)
        .then((response) => {
            setRequests(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const getChats = async () => {
        setIsLoading(true);
        await axios.get('/api/chats')
        .then((response) => {
            setChats(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const getTeam = async () => {
        setIsLoading(true);
        await axios.get('api/company/users')
        .then((response) => {
            setTeam(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const getVideos = async () => {
        try {
            const response = await axios.get('/api/learning');    
            if(response.status === 200){
                setVideos(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const getDuplicates = async () => {
    //     setIsLoading(true);

    //     await axios.get("/api/duplicates-graph")
    //     .then((response) => {
    //         let array = []
    //         const baseArray = [1,2,3,4,5,6,7,8,9,10,11]
    //         response.data.forEach(element => {
    //             array.push({type: element.typology_id, count: element.duplicates.length})
    //         });
            
    //         array = baseArray.map((i => type => array[i].type === type
    //                 ? array[i++].count
    //                 : 0
    //             )(0));

    //         setDuplicates(array);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     })
    //     .finally(() => {
    //         setIsLoading(false);
    //     })
    // }

    const getOverview = async () => {
        setIsLoading(true);

        await axios.get("/api/overview")
            .then((response) => {
                setOverview(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if (auth?.has_my_request) {
            getRequests();
        }
        getChats();
        if (auth?.has_team) {
            getTeam();
        }
        if (auth?.has_learning) {
            getVideos();
        }
        getOverview();
        // if (auth?.has_duplicates) {
        //     getDuplicates();
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="flex flex-col">
                <h1 className='text-2xl md:text-4xl font-bold text-primary'>{t('Dashboard.dashboard.nice_to_see_you')}, {auth?.first_name} {auth?.last_name}</h1>
                {/* PLAN */}
                <div className='block lg:hidden'>
                    <Plan />
                </div>
                <h1 className='text-xl md:text-3xl font-bold text-black pb-5 border-b-2'>Dashboard</h1>
                <SearchForm searchRequests={ getRequests } hasSort />
            </div>
            
            <div className='lg:grid xl:grid-cols-3 lg:grid-cols-6 flex flex-col h-full mt-10'>

                <div className='flex flex-col md:flex-row lg:hidden mx-auto'>
                    <button onClick={ () => { setTab(0) }}
                        className={`${tab === 0 ? 'bg-primary text-white' : 'bg-transparent text-black/30'} rounded-full font-bold px-4 py-2`}
                    >
                        {t('Dashboard.dashboard.your_requests')}
                    </button>
                    <button onClick={ () => { setTab(1) }}
                        className={`${tab === 1 ? 'bg-primary text-white' : 'bg-transparent text-black/30'} rounded-full font-bold px-4 py-2`}
                    >
                        {t('Dashboard.dashboard.inbox')}
                    </button>

                    {auth.is_dynamik_test &&
                        <button onClick={ () => { setTab(2) }}
                            className={`${tab === 2 ? 'bg-primary text-white' : 'bg-transparent text-black/30'} rounded-full font-bold px-4 py-2`}
                        >
                            {t('Dashboard.dashboard.duplicates')}
                        </button>
                    }
                </div>

                {/* MY REQUESTS */}
                {(auth?.has_my_request && tab == 0) &&
                    <div className='lg:hidden'>
                        <Requests requests={requests} />
                    </div>
                }

                {/* INBOX */}
                {tab == 1 &&
                    <div className='lg:hidden'>
                        <Inbox chats={chats} />
                    </div>
                }

                {/* DUPLICATES */}
                {auth.is_dynamik_test &&
                 tab == 2 &&
                    <div className='lg:hidden'>
                        <DuplicatesBanner />
                        {/* <Duplicates duplicates={duplicates} /> */}
                    </div>
                }

                <div className='xl:col-span-2 lg:col-span-4 hidden lg:flex flex-col lg:pr-8'>
                    {/* MY REQUESTS */}
                    {auth?.has_my_request &&
                        <Requests requests={requests} />
                    }

                    {/* INBOX */}
                    <Inbox chats={chats} />
                </div>

                <div className='hidden lg:block xl:col-span-1 lg:col-span-2 h-full'>
                    <div className='lg:flex lg:flex-col gap-5 justify-start items-center sticky top-10 h-screen'>

                        {/* ADVERTISEMENT */}
                        <div className='w-full rounded-[2rem] lg:h-[23vh] h-[300px] col-span-1 relative '>
                            <img src={FirstIcon} alt="First Icon" className='h-full w-full rounded-[2rem]' />
                            <div className='absolute top-10 h-full flex items-center w-full justify-center'>
                                <Link className='text-white underline'>{t('Dashboard.dashboard.view_more')}</Link>
                            </div>
                        </div>

                        {/* TEAM */}
                        {auth?.has_team &&
                            <Team team={team} />
                        }

                        {/* PLAN */}
                        <Plan teamCount={team?.length} requestsCount={requests?.length} />

                        {/* HIGHLIGHTS */}
                        {/* {(auth?.has_top_highlights && auth?.has_colour_highlights) &&
                            <Highlights />
                        } */}
                    </div>
                </div>
            </div>

            {/* DUPLICATES */}
            {/*auth?.has_duplicates &&
                <div className='hidden lg:block'>
                    <Duplicates duplicates={duplicates} />
                </div>
            */}

            {/* DUPLICATES */}
            {auth.is_dynamik_test &&
            <div className='hidden lg:block'>
                <DuplicatesBanner />
                {/* <Duplicates duplicates={duplicates} /> */}
            </div>
            }

            {/* OVERVIEW */}
            <Overview overview={overview} />

            {/* LEARNING */}
            {auth?.has_learning && 
                <>
                    <h1 className='font-bold text-3xl text-left mt-5'>Learning</h1>
                    <VideoSlider
                        videos={videos}
                    />
                </>
            }

        </>
    )
}

export default DashboardHomepage