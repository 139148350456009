import React, { useState, useEffect, useRef } from "react";
import axios from "../../Config/axios.js";
import ScrollToTop from "../../Router/ScrollToTop";
import StepPlanType from "../StepPlanType";
import StepBusinessForm from "../StepBusinessForm";
import StepAccountForm from "../StepAccountForm";
import Modal from "../../Modals/Modal";
import RegistrationSuccess from "../../Modals/RegistrationSuccess";
import ValidateEmail from "../../Modals/ValidateEmail";
import useAuthContext from "../../Config/AuthContext";
import { PLAN_TYPES } from "../../../constants/global";
import { useAlert } from "../../../contexts/AlertContext";
import StepPlan from "../StepPlan";

const Index = () => {
	const [planType, setPlanType] = useState(null);
	const [step, setStep] = useState(1);
	const [defaultStep1, setDefaultStep1] = useState(0);
	const [openRegistrationSuccessModal, setOpenRegistrationSuccessModal] = useState(false);
	const [openValidateEmailModal, setOpenValidateEmailModal] = useState(false);
	const { submitRegistrationForm } = useAuthContext();
	const { toggleLoader } = useAlert();
	const isMounted = useRef(false);

	const [formData, setFormData] = useState({
		plan_id: null,
		is_business: planType === PLAN_TYPES.BUSINESS
	});

	const prevStep = () => {
		switch (step) {
			case 1:
				setStep(1);
				break;
			case 2:
				setStep(step - 1);
				break;
			case 3:
				if (planType === PLAN_TYPES.INDIVIDUAL) {
					setStep(1);
				} else {
					setStep(step - 1);
				}
				break;
			default:
				setStep(step - 1);
				break;
		}
	};

	const nextStep = () => {
		if (step === 1 && planType === PLAN_TYPES.INDIVIDUAL) {
			setStep(step + 2);
		} else {
			setStep(step + 1);
		}
	};

	const chooseRoute = (response) => {
		setPlanType(response);
	};

	const handleFormData = (data) => {
		setFormData({ ...formData, ...data });
	};

	const submitForm = async () => {
		toggleLoader(true);
		const response = await submitRegistrationForm(formData)
		if (response.status === 200 || response.status === 204) {
			if (step === 3) {
				if (planType === PLAN_TYPES.BUSINESS) {
					setOpenRegistrationSuccessModal(true);
				} else {
					setOpenValidateEmailModal(true);
				}

				if (localStorage.getItem('guestRequest')) {
					saveStoredRequest(response.data)
				}
			} else {
				nextStep();
			}
		}
		toggleLoader(false);
	}

	const saveStoredRequest = async (user) => {
		const request = JSON.parse(localStorage.getItem('guestRequest'))

		await axios
        .post("/api/guest/requests", {
			user_id: user.id,
			first_name: request?.firstName,
			last_name: request?.lastName,
			nationality: request?.nationality,
			country_code: request?.phoneIndex,
			phone: request?.phoneNumber,
			show_phone: request?.showPhone,
			show_email: request?.showEmail,
			buyer_type_id: request?.buyerType.id,
			urgency_id: request?.urgency.id,
			investment_type_id: request?.investmentType.id,
			request_type_id: request?.requestType.id,
			property_types: JSON.stringify(request?.propertyTypes),
			zones: request?.zone,
			price_until: request?.priceUntil,
			area_from: request?.areaFrom,
			condition_id: request?.condition.id,
			typology_id: request?.typology.id,
			bathroom_id: request?.bathroom.id,
			amenities: JSON.stringify(request?.amenities),
			brief_description: request?.brief,
			detailed_description: request?.description,
        })
        .then((response) => {
            localStorage.removeItem('guestRequest');
        })
        .finally(() => {
            //
        });
	}

	const setDefaultFunction = () => {
		setDefaultStep1(0);
	};

	const setDefaultFunctionPositive = () => {
		setDefaultStep1(1);
	};

	const handlePlanSelection = (selection) => {
		setFormData({...formData, plan_id: selection.plan, permanence_period_id: selection.permanencePeriod, is_business: planType === PLAN_TYPES.BUSINESS})
	}

	useEffect(() => {
		if (isMounted.current && (step === 2 || step === 3)) {
			submitForm()
		} else {
			isMounted.current = true
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData])
	

	return (
		<>
			<Modal isOpen={openRegistrationSuccessModal}>
				<RegistrationSuccess
					onClose={() => {
						setOpenRegistrationSuccessModal(false);
						window.location.href = "/login";
					}}
					isBusiness={planType === PLAN_TYPES.BUSINESS}
				/>
			</Modal>

			<Modal isOpen={openValidateEmailModal}>
				<ValidateEmail
					email={formData.user_email}
					onClose={() => {
						setOpenValidateEmailModal(false);
						window.location.href = "/login";
					}}
				/>
			</Modal>

			{step === 1 && (
				<>
					<ScrollToTop />
					<StepPlanType
						chooseRoute={chooseRoute}
						default={defaultStep1}
						nextStep={nextStep}
						setDefault={setDefaultFunction}
						selectedPlanType={planType}
					/>
				</>
			)}

			{(step === 1 && planType) && (
				<StepPlan
					selectedPlanType={planType}
					onPlanSelection={(selection) => handlePlanSelection(selection)}
					nextStep={nextStep}
					prevStep={prevStep}
				/>
			)}

			{step === 2 && (
				<>
					<ScrollToTop />
					<StepBusinessForm prevStep={prevStep} nextStep={nextStep} setData={handleFormData} />
				</>
			)}

			{step === 3 && (
				<>
					<ScrollToTop />
					<StepAccountForm isIndividualAccount={planType === PLAN_TYPES.INDIVIDUAL} prevStep={prevStep} nextStep={nextStep} setData={handleFormData} />
				</>
			)}

			{/* 
            {
                step == 4 && type == "business" &&
                <><ScrollToTop /><Step5Business registerUser={registerBusinessUser} /></>
            } */}
		</>
	);
};

export default Index;
