import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import User0 from "../../assets/Dashboard/Team/User0.png";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../UI/ErrorMessage";
import useAuthContext from "../Config/AuthContext";
import { useAlert } from "../../contexts/AlertContext";
import { COMPANY_ROLES } from "../../constants/global";
import { useTranslation } from 'react-i18next';

const CreateUser = ({ onClose, teamAdmin, updateTeam }) => {
  const { addUserToTeam } = useAuthContext();
  const { triggerPopup, toggleLoader } = useAlert();
  const { t } = useTranslation();

  const [profileFormData, setProfileFormData] = useState({
    is_admin: 0,
    first_name: "",
    last_name: "",
    //user_type: "",
    occupation: "",
    email: "",
    contact_email: "",
    phone: "",
    plan_id: teamAdmin.plan_id,
    company_id: teamAdmin.company_id,
    company_role_id: COMPANY_ROLES.USER,
    expiration_date: teamAdmin.expiration_date,
    profile_picture: [],
  });
  const [errors, setErrors] = useState([]);

  const handleProfilePicture = (e) =>
  {
    if(e.target.files[0].size < 1000000) {
      setProfileFormData({
        ...profileFormData,
        profile_picture: e.target.files[0] || [],
      });
    } else {
      triggerPopup('Popups.filesize_error');
    }
  }

  const submitForm = async () => {
    toggleLoader(true);

    const fd = new FormData();
    // convert obj to FormData
    Object.entries(profileFormData).forEach(([key, value]) => {
      if (
        (key == "profile_picture" && value.length != 0) ||
        key != "profile_picture"
      ) {
        fd.append(key, value);
      }
    });

    const response = await addUserToTeam(fd);
    toggleLoader(false);
    if (response.status === 204) {
      triggerPopup(t('Popups.added_user'));
      updateTeam();
      onClose();
    } else {
      setErrors(response.data.errors);
    }
  };

  return (
    <div className="w-screen h-screen z-[100] fixed bg-black/10 top-0 left-0 flex items-center justify-center">
      <div className="bg-white rounded-3xl w-4/5 lg:max-w-3xl h-4/5 shadow-custom p-5 2xl:p-10 overflow-x-hidden overflow-y-auto">
        <div className="w-full flex justify-between items-center">
          <h1 className="text-lg md:text-2xl text-primary">{t('Dashboard.team.add_new_user')}</h1>
          <button onClick={onClose} className="text-primary text-2xl md:text-4xl md:mx-3">
            <AiOutlineClose />
          </button>
        </div>
        <div className="flex flex-col items-center w-full mt-3">
          <div className="flex justify-start w-full border-b-2 pb-1">
            <h1 className="text-lg md:text-2xl">{t('Dashboard.team.profile')}</h1>
          </div>
          <div className="w-full flex flex-col justify-center items-center py-5">
            <img
              src={
                profileFormData.profile_picture.length == 0
                  ? User0
                  : URL.createObjectURL(profileFormData.profile_picture)
              }
              className="w-20 h-20 md:w-24 md:h-24 rounded-full object-cover"
              alt="New User"
            />
            <div className="mt-2 text-center text-sm md:text-base">
              <p className='text-black/40'>{t('Dashboard.team.max_filesize')}</p>
              <label
                for="uploadFile"
                className="text-primary hover:text-primary/70 mt-2 cursor-pointer"
              >
                {t('Dashboard.team.upload_photo')}
              </label>
            </div>
            <input
              className="hidden"
              type="file"
              id="uploadFile"
              onChange={(e) => { handleProfilePicture(e) }}
            ></input>
			      <ErrorMessage errors={errors.profile_picture} />
          </div>
          <div className="w-full flex flex-col">
            <div className="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5 mb-2 md:my-5">
              <div>
                <input
                  onChange={(e) => {
                    setProfileFormData({
                      ...profileFormData,
                      first_name: e.target.value,
                    });
                  }}
                  type="text"
                  className="w-full border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg"
                  placeholder={t('Dashboard.team.first_name')}
                />
                <ErrorMessage errors={errors.first_name} />
              </div>
              <div>
                <input
                  onChange={(e) => {
                    setProfileFormData({
                      ...profileFormData,
                      last_name: e.target.value,
                    });
                  }}
                  type="text"
                  className="w-full border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg"
                  placeholder={t('Dashboard.team.last_name')}
                />
                <ErrorMessage errors={errors.last_name} />
              </div>
            </div>
            <input
              onChange={(e) => {
                setProfileFormData({
                  ...profileFormData,
                  occupation: e.target.value,
                });
              }}
              type="text"
              className="border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg"
              placeholder={t('Dashboard.team.occupation')}
            />
            <ErrorMessage errors={errors.occupation} />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex justify-start w-full border-b-2 mt-5">
            <h1 className="text-lg md:text-2xl">{t('Dashboard.team.access')}</h1>
          </div>
          <input
            onChange={(e) => {
              setProfileFormData({ ...profileFormData, email: e.target.value });
            }}
            type="email"
            className="border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg w-full mt-5"
            placeholder={t('Dashboard.team.email_address')}
          />
          <ErrorMessage errors={errors.email} />
          {/* <div className="w-full flex items-center mt-5">
						<label className="switch mr-5">
							<input
								onChange={(e) => {
									setProfileFormData({ ...profileFormData, block: e.target.value });
								}}
								type="checkbox"
							/>
							<span className="slider round"></span>
						</label>
						<p className="text-black/40 text-base">Block Access</p>
					</div> */}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex justify-start w-full border-b-2 mt-5 pb-1">
            <h1 className="text-lg md:text-2xl">{t('Dashboard.team.contact_details')}</h1>
          </div>
          <input
            onChange={(e) => {
              setProfileFormData({
                ...profileFormData,
                contact_email: e.target.value,
              });
            }}
            type="email"
            className="border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg w-full mt-5"
            placeholder={t('Dashboard.team.contact_email')}
            value={profileFormData.contact_email ?? null}
          />
          <ErrorMessage errors={errors.contact_email} />
          <button
            onClick={() => {
              setProfileFormData({
                ...profileFormData,
                contact_email: profileFormData.email,
              });
            }}
            className="underline text-primary text-base w-full text-start mt-1"
          >
            {t('Dashboard.team.same_as_above')}
          </button>
          <PhoneInput
            country={"pt"}
            onChange={(number, country) => {
              setProfileFormData({
                ...profileFormData,
                phone: number.replace(country.dialCode, ""),
                phone_country_code: `+${country.dialCode}`,
              });
            }}
            buttonStyle={{ border: "none", backgroundColor: "white" }}
            inputStyle={{
              height: "100%",
              width: "100%",
              border: "none",
              fontSize: "18px",
              lineHeight: "28px",
            }}
            containerStyle={{
              fontSize: "18px",
              lineHeight: "28px",
              padding: "4px",
              border: "2px solid #e5e7eb",
              borderRadius: "8px",
              marginTop: "12px",
            }}
            enableSearch={true}
            disableDropdown={false}
            defaultMask="... ... ..."
            placeholder="Telephone Number 1"
          />
          <ErrorMessage errors={errors.phone} />
        </div>
        <div className="flex w-full justify-center mt-5">
          <button
            onClick={submitForm}
            className="py-2 px-4 md:px-10 lg:px-20 bg-primary text-white text-sm md:text-xl rounded-full"
          >
            {t('Dashboard.team.add_new_user')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
