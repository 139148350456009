import React from "react";
import { API_PATHS, COMPANY_ROLES } from "../../../constants/global";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

const TableViewCard = ({ currentUser, member, isAdmin, onEdit, onDelete }) => {

	const { t } = useTranslation();

	return (
		<>
			{
				<div
					key={member.id}
					className={`${
						member.id === currentUser.id ? "bg-black/5" : ""
					} md:col-span-4 relative rounded-2xl shadow-custom mx-auto h-48 w-48 md:w-52 md:h-52 lg:w-60 lg:h-60 2xl:w-72 2xl:h-72`}
				>
					<div className="absolute flex flex-col items-center justify-center h-full w-full top-0 left-0">
						<div className="w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 rounded-full">
							<img
								src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${member.profile_image}`}
								alt="User Profile"
								className="w-full h-full rounded-full object-cover"
							/>
						</div>
						<h1 className="text-lg md:text-xl lg:text-2xl font-bold">{member.first_name}</h1>
						<p className="break-all text-center text-sm lg:text-base">
							<span>{member.email}</span>
						</p>
						<p className="break-all text-center text-sm lg:text-base">
						{t('Dashboard.team.phone')} <span>{member.phone}</span>
						</p>
						<div className="flex justify-around items-center w-full my-1 xl:my-2">
							{(isAdmin || currentUser.id === member.id) && (
								<button onClick={onEdit} className="flex text-primary items-center text-sm lg:text-base">
									<p className="text-lg lg:text-2xl mx-2">
										<AiOutlineEye />
									</p>
									{t('Dashboard.team.view')}
								</button>
							)}
						</div>
						<div className="absolute top-5 right-5 text-black/40">
							{member.company_role_id === COMPANY_ROLES.ADMIN ? (
								<p className="text-xs lg:text-base">{t('Dashboard.team.admin')}</p>
							) : (
								isAdmin && (
									<button onClick={onDelete} className="text-primary text-2xl 2xl:text-3xl">
										<BsTrashFill />
									</button>
								)
							)}
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default TableViewCard;
