import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import LocationIcon from "../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { FaPhoneAlt } from "react-icons/fa";
import { BsFillChatLeftFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../Config/axios";
import useAuth from "../../hooks/useAuth";
import { currentLanguage } from "../Config/i18n";

const RequestInfo = ({ request, onClose, onViewAmenities, onReport, hasContactInfo = false }) => {
	const [showPhone, setShowPhone] = useState(false);
	const { t } = useTranslation();
	const { auth } = useAuth();
	const navigate = useNavigate();

	const createChat = async (requestId) => {
		await onClose();
		navigate(`/app/messages/${requestId}`);
		await axios.post("/api/chats/create", {
			request_id: requestId,
		});
	};

	const handleShowPhone = () => {
		if (!showPhone) {
			axios.post(`/api/update-request-statistics`, {
				id: request.id,
				clicked: true
			});
		}
        setShowPhone(!showPhone);
    }

	return (
		<>
			{request && (
				<div className="w-screen h-screen z-[100] fixed bg-black/10 top-0 left-0 flex items-center justify-center">
					<div className="bg-white rounded-3xl w-4/5 lg:max-w-3xl h-4/5 shadow-custom p-5 2xl:p-10 overflow-x-hidden overflow-y-auto">
						<div className="flex flex-col border-b-2 w-full">
							<div className="w-full flex justify-end">
								<button onClick={() => { onClose() }} className="text-primary text-3xl">
									<AiOutlineClose />
								</button>
							</div>
							<h1 className="bg-fourth py-1 text-primary flex justify-center font-bold text-xl rounded-full shadow-xl w-[200px] m-auto">
								{currentLanguage() === 'en' ? request.request_type_name : request.request_type_name_pt}
							</h1>
							<div className="w-full flex justify-center mt-5">
								<img
									src={`/svg/properties/${request.property_type_image}`}
									alt="Property Icon"
									className="w-36 2xl:w-52"
								/>
							</div>
							<div className="flex flex-col items-center mt-2">
								<h1 className="text-lg md:text-2xl text-black/60 font-bold mb-2">
									{currentLanguage() === 'en' ? `${request.property_type_name} (${request.typology_name})` : `${request.property_type_name_pt} (${request.typology_name_pt})`}
								</h1>
								<h1 className="flex flex-col md:flex-row font-bold md:text-2xl text-center md:gap-x-2">
									<p>
										{ t('Dashboard.my_requests.max_budget') } -{" "}
										{(parseFloat(request.price_until) > 0)
										? `${request.price_until} €`
										: t('Dashboard.my_requests.no_limit')}
									</p>
								</h1>
							</div>
							<div className="w-full flex flex-col justify-center gap-x-5 pb-3 items-center">
								<p className="text-sm md:text-lg">
									ID: <span>{request.id}</span>
								</p>
								<div className="flex items-center">
									<img src={LocationIcon} alt="Location Icon" className="text-primary w-5 md:w-[25px]" />
									<p className="text-sm md:text-base text-primary ml-2">{t('Dashboard.my_requests.in')} {
										Object.keys(request.locations).map((key, index) => { 
											return `${request.locations[key].name}${index != Object.keys(request.locations).length - 1 ? ", " : ""}`
										})
									}</p>
								</div>
							</div>
						</div>
						<div className="flex flex-col w-full justify-between 2xl:px-5 mt-5">
							<div className="whitespace-pre-line break-words text-sm md:text-base">
								<p>{request.brief_description}</p>
								<h1 className="text-black/60 font-bold text-xl py-4">{t('Dashboard.my_requests.details')}</h1>
								<p>{request.detailed_description}</p>
							</div>
							<div className="flex justify-between pt-5 text-sm md:text-base mb-3 w-full">
								<div className="flex justify-start gap-2 flex-wrap">
									<div className="flex items-center bg-primary/20 rounded-full px-3 text-md gap-x-1">
										<p>
											{(parseFloat(request.area_from) > 0)
											? `${request.area_from} m²`
											: t('Dashboard.my_requests.no_minimum')}
										</p>
									</div>
									<div className="bg-primary/20 rounded-full px-3 text-md">
										{request.bathroom_name}
										{" "}{Number(request.bathroom_name) > 1 ? t('Dashboard.my_requests.bathrooms') : t('Dashboard.my_requests.bathroom')}
									</div>
									{request?.amenities.map((amenity, index) => {
										if (index <= 3 ) {
											return (
												<div key={index} className=" bg-primary/20 rounded-full px-3">
													{ currentLanguage() === 'en' ? amenity.name : amenity.name_pt }
												</div>
											);
										}
									})}
									{request?.amenities?.length > 3 && (
										<div>
											<button onClick={() => onViewAmenities()} className="underline whitespace-nowrap">
											{t('Dashboard.my_requests.view_all')}
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="border-b-2 text-sm md:text-base 2xl:text-lg">
								<p className="mt-4">
									<span className="text-primary font-bold">{t('Dashboard.my_requests.buyer_type')}:</span>{" "}
									{ currentLanguage() === 'en' ? request.buyer_type_name : request.buyer_type_name_pt }
								</p>
								<p>
									<span className="text-primary font-bold">{t('Dashboard.my_requests.urgency')}:</span>{" "}
									{ currentLanguage() === 'en' ? request.urgency_name : request.urgency_name_pt }
								</p>
								<p className="mb-4">
									<span className="text-primary font-bold">{t('Dashboard.my_requests.investment_type')}:</span>{" "}
									{ currentLanguage() === 'en' ? request.investment_type_name : request.investment_type_name_pt }
								</p>
								<p className="text-sm font-bold mb-4">
									{t('Dashboard.my_requests.published')} {request.created_at}
								</p>
							</div>
							<div className="hidden md:flex gap-x-4 items-center justify-between py-4">
								<div className="flex gap-x-5 items-center">
									<img
										src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request.created_by_image}`}
										alt="User Profile"
										className="w-16 h-16 2xl:w-20 2xl:h-20 rounded-full object-cover"
									/>
									<div>
										<h1 className="font-bold 2xl:text-xl">
											{request.created_by_name}
										</h1>
										{request.company_id != null &&
											<img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${request.company_logo}`} alt={request.company_name} className="h-14 object-contain" />
										}
										{request.is_individual && 
											<img src={request.individual_logo} alt="Particular" className="h-8 object-contain" />
											// <div className="h-10 mx-auto">
											// </div>
										}
										<p className="text-primary text-base font-bold">
											{currentLanguage() === 'en' ? request.created_by_plan_name : request.created_by_plan_name_pt }
										</p>
									</div>
								</div>
								
								<div className="flex items-center gap-x-5 2xl:px-10">
									<button
										onClick={() => onReport() }
										className="flex md:flex-row flex-col w-full text-primary justify-end md:items-center items-end gap-x-2"
									>
										<p className="2xl:text-lg lg:block hidden">{t('Dashboard.requests.report')}</p>
										<div className="2xl:text-3xl text-2xl font-bold bg-lightPrimary border-primary border-2 rounded-full 2xl:w-8 2xl:h-8 w-7 h-7 flex justify-center items-center">
											!
										</div>
									</button>
									{request.show_phone == true &&
										<div className="flex flex-col justify-center items-center">
											<button
												onClick={() => { handleShowPhone() }}
												className="text-2xl text-primary"
											>
												<FaPhoneAlt />
											</button>
											{showPhone && (
												<p className="text-primary text-base text-center">{request.created_by_phone}</p>
											)}
										</div>
									}
									{request.created_by !== auth.id && (
										<button
											onClick={() => { createChat(request.id)}}
											className="text-2xl text-primary"
										>
											<BsFillChatLeftFill />
										</button>
									)}
								</div>
							</div>
							{/* REQUEST OWNER MOBILE */}
							<div className="md:hidden space-y-1 py-2">
								{request.company_id != null ? (
									<>
									<div className="flex items-center justify-between">
										<div className="w-12 h-12 rounded-full overflow-hidden">
											<img
												src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request.created_by_image}`}
												alt="User Profile"
												className="h-full w-full object-cover"
											/>
										</div>
										<img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${request.company_logo}`} alt={request.company_name} className="h-14 object-contain" />
									</div>
									{!hasContactInfo &&
									<h1 className="font-bold">
										{request.created_by_name}
									</h1>
									}
									</>
								) : (
									<div className="flex items-center gap-x-2">
										<div className="w-12 h-12 rounded-full overflow-hidden">
											<img
												src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request.created_by_image}`}
												alt="User Profile"
												className="h-full w-full object-cover"
											/>
										</div>
										{request.is_individual && 
											<img src={request.individual_logo} alt="Particular" className="h-8 object-contain" />
										}

										{!hasContactInfo &&
										<h1 className="font-bold">
											{request.created_by_name}
										</h1>
										}
									</div>
								)}
								{hasContactInfo &&
								<div className="flex items-center md:items-end justify-between text-sm">
									<div className="space-y-1">
										<h1 className="font-bold">
											{request.created_by_name}
										</h1>
										
											<>
											<div className="flex text-primary items-center gap-x-4">
												{request.created_by_plan_name && request.created_by_plan_name_pt &&
													<p className="font-bold">
														{currentLanguage() === 'en' ? request.created_by_plan_name : request.created_by_plan_name_pt }
													</p>
												}
												<button onClick={() => onReport() } className="ml-auto bg-lightPrimary border-primary border-2 rounded-full w-5 h-5 flex justify-center items-center font-bold text-primary">
													!
												</button>
												{ request.show_phone == true &&
													<button onClick={() => { handleShowPhone() }}>
														<FaPhoneAlt />
													</button>
												}
												{request.created_by !== auth.id && (
													<button onClick={() => createChat(request.id)}>
														<BsFillChatLeftFill />
													</button>
												)}
											</div>
											{showPhone &&
												<p className="text-primary">{request.created_by_phone}</p>
											}
											</>
									</div>
								</div>
								}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default RequestInfo;
