import React, { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useAlert } from "../../contexts/AlertContext";
import Modal from "../Modals/Modal";
import BlockedAccessModal from "../Modals/BlockedAccessModal";
import useAuthContext from "../Config/AuthContext";
import DashboardNavbar from '../../components/Dashboard/Navbar';
import Banner from '../../assets/Dashboard/Banner.png';
import ScrollToTop from '../../components/Router/ScrollToTop';
import Footer from "../Dashboard/Footer";
import axios from "../Config/axios";
import { APP_PATHS } from "../../constants/global";
import CTA from "../UI/CTA";

export default function PrivateLayout() {
  const { auth, setHasMessage } = useAuth();
  const { logout } = useAuthContext();
  const { toggleLoader } = useAlert();
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);

  const canAccess = () => {
    if ((location.pathname.includes(APP_PATHS.ADMIN)
      || location.pathname.includes(APP_PATHS.REQUESTS)
      || location.pathname.includes(APP_PATHS.LEARNING)
      || location.pathname.includes(APP_PATHS.STORE)
      || location.pathname.includes(APP_PATHS.DASHBOARD)
      || location.pathname.includes(APP_PATHS.DUPLICATES)
      || location.pathname.includes(APP_PATHS.TEAM))
      && auth?.is_individual
    ) {
      return false
    }
    return true
  }

  useEffect(() => {
    toggleLoader(isLoading);
    if (auth) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (auth?.id) {
      axios.get('/api/has-new-messages')
      .then((response) => {
        setHasMessage(response.data);
      });
      let interval = setInterval(() => {
        axios.get('/api/has-new-messages')
        .then((response) => {
          setHasMessage(response.data);
        });
      }, 5000);
      return () => clearInterval(interval);
    } else {
      setHasMessage(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return !isLoading ? (
    auth?.id ? (
      canAccess() ? (
        <>
          <Modal isOpen={ auth?.blocked }>
            <BlockedAccessModal
              title={'Your access has been blocked'}
              description={'To continue using the platform, talk to your account admin.'}
              onConfirm={() => logout()}
            />
          </Modal>
          <ScrollToTop />
          <img src={Banner} alt="Banner" />
          <DashboardNavbar />
          <section className="lg:ml-[12vw] pb-20 min-h-[150vh] xl:px-20 md:px-10 px-3 pt-20">
            <Outlet />
          </section>
          <Footer />
          <CTA />
        </>
      ) : (
        <Navigate
          to={ location.state?.from?.pathname ?? (auth?.is_individual || !auth?.has_requests)
            ? APP_PATHS.MY_REQUESTS
            : APP_PATHS.DASHBOARD
          }
          state={{ from: location }}
          replace
        />
      )
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  ) : null;
}
