import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RegisterNowDevices from '../../../assets/Index/register-now-devices.png';

const RegisterNow = () => {

    const { t } = useTranslation();

    return (
        <section className='space-y-10 mt-10 2xl:space-y-20 2xl:mt-20'>
            <h2 className='font-bold text-2xl md:text-4xl xl:text-5xl leading-tight text-center'>{t("Homepage.register_now.title")}</h2>
            <div className='relative bg-primary pt-5 pb-10 2xl:py-20'>
                    <div className='text-white mx-10 md:mx-20 max-w-xs lg:max-w-3xl 2xl:max-w-7xl lg:mx-auto'>
                        <p className='text-lg md:text-2xl lg:text-4xl mb-6 w-4/6 max-w-[200px] md:w-full md:max-w-full lg:max-w-sm'>{t("Homepage.register_now.description")}</p>
                        <Link
                            to="/pricing"
                            className='font-bold py-2 px-4 md:px-8 bg-customPurple text-sm md:text-base text-center rounded-md'
                        >
                            {t("Homepage.make_your_request")}
                        </Link>
                    </div>
                <img className='absolute bottom-0 -right-8 md:-bottom-1/3 lg:-bottom-1/2 md:right-0 2xl:right-1/4 2xl:translate-x-1/2 w-1/2 md:w-full max-w-[280px] md:max-w-sm lg:max-w-lg 2xl:max-w-2xl' src={RegisterNowDevices} alt='Dispositivos' />
            </div>
        </section>
    )
}

export default RegisterNow