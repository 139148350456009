import { useEffect, useState } from "react"
import { t } from "i18next"
import Pagination from '../../UI/Pagination';
import NotificationCard from "./NotificationCard";
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";

const Notifications = () => {

    const { toggleLoader } = useAlert()
    const [notifications, setNotifications] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const getNotifications = async () => {
        setIsLoading(true)
        await axios.get("/api/notifications")
        .then((response) => {
            setNotifications(response.data)
        })
        .catch((e) => {
            console.log(e)
        })
        .finally(() => {
            setIsLoading(false)
        });
    }

    useEffect(() => {
        getNotifications()
    }, [currentPage])

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])
    
    return (
        <>
            <div className="flex flex-col md:pb-20 pb-10">
                <div className="w-full flex justify-between items-center md:py-5 py-2 border-b-2">
                    <h1 className="text-3xl font-bold text-black">{t('Dashboard.notifications.title')}</h1>
                </div>
            </div>

            {(notifications)?.length > 0 ? (
            <>
                <div className="flex flex-col w-full shadow-custom rounded-3xl">
                    {(notifications)?.map((notification, index) =>
                        <NotificationCard
                            notification={notification}
                            isFirst={index == 0}
                        />
                    )}
                </div>
                <Pagination
					itemCount={notifications.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>
            </>
        ) : (
            <div className="w-full flex justify-center text-primary text-3xl font-bold">
                {t("Dashboard.notifications.no_notifications")}
            </div>
        )}
        </>
    )
}

export default Notifications