import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationIcon from "../../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { FaPhoneAlt } from "react-icons/fa";
import { BsFillChatLeftFill } from "react-icons/bs";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import axios from "../../Config/axios";
import useAuth from "../../../hooks/useAuth";
import { currentLanguage } from "../../Config/i18n";

const RequestCard = ({ request, onDetails, onViewAmenities, onReport, unauth = false }) => {
	const [showPhone, setShowPhone] = useState(false);
	const { auth } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

	const createChat = async (requestId) => {
		await axios.post("/api/chats/create", {
			request_id: requestId,
		}).then(() => 
        {
            navigate(`/app/messages/${requestId}`);
        });
	};

    const handleShowPhone = () => {
        if (!showPhone) {
            axios.post(`/api/update-request-statistics`, {
                id: request?.id,
                clicked: true
            });
        }
        setShowPhone(!showPhone);
    }

    const handleClick = () => {
        if (unauth) {
            navigate('/pricing')
        }
    }

    return (
        <>
            {/* MOBILE */}
            <div onClick={ () => handleClick() } className={`lg:hidden p-5 h-fit bg-white rounded-3xl w-full my-5 shadow-custom space-y-4 ${unauth && 'cursor-pointer'}`}>
                {/*PROPERTY*/}
                <div className="flex items-start gap-1">
                    <img
                        src={`/svg/properties/${request?.property_type_image}`}
                        alt="Property Type Icon"
                        className="w-16"
                    />
                    <div>
                        <h1 className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `${request?.property_type_name}` : `${request?.property_type_name_pt}`}
                        </h1>
                        <h1 className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `(${request?.typology_name})` : `(${request?.typology_name_pt})`}
                        </h1>
                    </div>
                    {!unauth &&
                        <button onClick={() => onReport() } className="ml-auto bg-lightPrimary border-primary border-2 rounded-full w-6 h-6 flex justify-center items-center font-bold text-primary text-lg">
                            !
                        </button>
                    }
                </div>
                <div className="ml-2">
                    <div className="flex text-primary items-start">
                        <img
                            src={LocationIcon}
                            alt=""
                            className="text-primary w-4 mr-2"
                        />
                        <p className="line-clamp-2 text-sm">{t('Dashboard.my_requests.in')} {
                            Object.keys(request?.locations).map((key, index) => { 
                                return `${request?.locations[key].name}${index != Object.keys(request?.locations).length - 1 ? ", " : ""}`
                            })
                        }</p>
                    </div>
                    <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                        {currentLanguage() === 'en' ? request?.request_type_name : request?.request_type_name_pt}
                    </p>
                    <p className="text-xs">ID: {request?.id}</p>
                </div>
                {/*PRICE && AREA*/}
                <div className="md:flex md:items-center md:justify-between">
                    <div>
                        <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.max_budget')}</h1>
                        <div className="flex font-bold justify-between text-sm">
                            {(parseFloat(request?.price_until) > 0)
                            ? `${request?.price_until} €`
                            : t('Dashboard.my_requests.no_limit')}
                        </div>
                    </div>
                    <div>
                        <h1 className="font-bold text-black/60 mt-2">{t('Dashboard.my_requests.useful_area')}</h1>
                        <div className="flex font-bold justify-between text-sm">
                            {parseFloat(request?.area_from) > 0
                            ? `${request?.area_from} m²`
                            : t('Dashboard.my_requests.no_minimum')}
                        </div>
                    </div>
                </div>
                {/* REQUEST OWNER */}
                <div className={`space-y-1 ${ unauth && 'blur-sm' }`}>
                    <div className="flex items-center justify-between">
                        <div className="w-12 h-12 rounded-full overflow-hidden">
                            <img

                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request?.created_by_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                        {(request?.company_id != null && !unauth) && 
                            <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${request?.company_logo}`} alt={request?.company_name} className="h-14 object-contain" />
                        }
                        {(request?.is_individual && !unauth) && 
                            <img src={request?.individual_logo} alt="Particular" className="h-6 object-contain" />
                        }
                    </div>
                    <div className="flex items-end justify-between text-sm">
                        <div className="space-y-1">
                            <h1 className="font-bold">
                                {request?.created_by_name}
                            </h1>
                            <div className="flex text-primary items-center gap-x-4">
                                {request?.created_by_plan_name && request?.created_by_plan_name_pt &&
                                    <p className="font-bold">
                                        {currentLanguage() === 'en' ? request?.created_by_plan_name : request?.created_by_plan_name_pt }
                                    </p>
                                }
                                { request?.show_phone == true &&
                                    <button onClick={() => { handleShowPhone() }}>
                                        <FaPhoneAlt />
                                    </button>
                                }
                                {request?.created_by !== auth?.id && (
                                    <button onClick={() => createChat(request?.id)}>
                                        <BsFillChatLeftFill />
                                    </button>
                                )}
                            </div>
                            {showPhone &&
                                <p className="text-primary">{request?.created_by_phone}</p>
                            }
                        </div>
                        <button onClick={() => onDetails() } className="text-primary font-bold">
                            {t('Dashboard.requests.details')}
                        </button>
                    </div>
                </div>
            </div>

            {/* DESKTOP */}
            <div onClick={ () => handleClick() } className={`2xl:py-10 xl:py-8 py-5 lg:h-fit h-40 2xl:px-10 xl:px-8 px-5 bg-white lg:rounded-[3rem] rounded-[2rem] w-full my-5 shadow-custom lg:grid grid-cols-6 xl:grid-cols-7 2xl:grid-cols-11 hidden ${unauth && 'cursor-pointer'}`}>
                {/*PROPERTY*/}
                <img
                    src={`/svg/properties/${request?.property_type_image}`}
                    alt="Property Type Icon"
                    className="col-span-1 m-auto w-3/4"
                />
                <div className="space-y-4 2xl:col-span-2">
                    <h1 className="2xl:text-xl text-base font-bold text-black/60">
                    {currentLanguage() === 'en' ? `${request?.property_type_name} (${request?.typology_name})` : `${request?.property_type_name_pt} (${request?.typology_name_pt})`}
                    </h1>
                    <div className="flex text-primary items-center">
                        <div className="lg:mr-2">
                            <img
                                src={LocationIcon}
                                alt="Location Icon"
                                className="text-primary lg:w-[24px] w-[18px]"
                            />
                        </div>
                        <p className="lg:text-base text-sm">{t('Dashboard.my_requests.in')} {
                            Object.keys(request?.locations).map((key, index) => { 
                                return `${request?.locations[key].name}${index != Object.keys(request?.locations).length - 1 ? ", " : ""}`
                            })
                        }</p>
                    </div>
                    <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                        {currentLanguage() === 'en' ? request?.request_type_name : request?.request_type_name_pt}
                    </p>
                    <p className="pt-4 text-base">ID: {request?.id}</p>
                </div>

                {/*AMENITIES & DESCRIPTION*/}
                <div className="lg:flex hidden flex-col justify-between xl:px-5 px-2 col-span-2 xl:col-span-3 2xl:col-span-3">
                    <div className="2xl:text-lg text-md">
                        <h1 className="break-words">
                            {request?.brief_description}
                        </h1>
                        <button
                            onClick={() => onDetails() }
                            className="text-primary inline-block"
                        >
                            <BsFillPlusCircleFill />
                        </button>
                    </div>
                    <div className="flex flex-col xl:flex-row items-start xl:justify-between w-full gap-2 xl:gap-0">
                        <div className="flex justify-start flex-wrap gap-2">
                            {request?.amenities?.map(
                                (amenity, index) =>
                                    index < 3 && (
                                    <div
                                        key={index}
                                        className=" bg-lightPrimary rounded-full text-sm px-2"
                                    >
                                        { currentLanguage() === 'en' ? amenity.name : amenity.name_pt }
                                    </div>
                                )
                            )}
                            <div className="bg-lightPrimary rounded-full text-sm px-2">
                                {parseFloat(request?.area_from) > 0
                                ? `${request?.area_from} m²`
                                : t('Dashboard.my_requests.no_minimum')}
                            </div>
                        </div>
                        {request?.amenities && (
                            <button
                                onClick={() => { onViewAmenities() }}
                                className="underline text-sm xl:text-md whitespace-nowrap"
                            >
                                {t('Dashboard.my_requests.view_all')}
                            </button>
                        )}
                    </div>
                </div>
                {/*PRICE & REPORT*/}
                <div className="flex flex-col justify-between items-end col-span-1 2xl:col-span-2 mr-6">
                    <div className="text-right lg:flex hidden h-full flex-col">
                        <h1 className="font-bold text-md">{t('Dashboard.my_requests.max_budget')}</h1>
                        <div className="2xl:text-lg mb-10 whitespace-nowrap">
                            <p>
                                {(parseFloat(request?.price_until) > 0)
                                ? `${request?.price_until} €`
                                : t('Dashboard.my_requests.no_limit')}
                            </p>
                        </div>
                    </div>
                    {!unauth &&
                        <button
                            onClick={() => onReport() }
                            className="flex lg:flex-row flex-col w-full text-primary justify-end lg:items-center items-end gap-x-2"
                        >
                            <p className="2xl:text-lg lg:block hidden">{t('Dashboard.requests.report')}</p>
                            <div className="2xl:text-3xl text-2xl font-bold bg-lightPrimary border-primary border-2 rounded-full 2xl:w-8 2xl:h-8 w-7 h-7 flex justify-center items-center">
                                !
                            </div>
                            <div className="lg:hidden block bg-white text-primary font-bold text-sm shadow-xl rounded-full px-2 mt-2">
                            {t('Dashboard.my_requests.view_all')}
                            </div>
                        </button>
                    }
                </div>
                {/* DESKTOP */}
                <div className={`hidden 2xl:flex items-center justify-center gap-x-5 col-span-2 2xl:col-span-3 ${ unauth && 'blur-sm' }`}>
                    <div className="flex flex-col items-end justify-center gap-y-2">
                        <h1 className="font-bold text-lg">
                            {request?.created_by_name}
                        </h1>                            
                        {(request?.company_id != null && !unauth) && 
                            <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${request?.company_logo}`} alt={request?.company_name} className="h-14 object-contain" />
                        }
                        {(request?.is_individual && !unauth) && 
                            <img src={request?.individual_logo} alt="Particular" className="h-8 object-contain" />
                        }
                        {/* <p className="font-bold text-primary">{request?.company_name}</p> */}
                        <div className="flex text-primary items-center justify-end gap-x-4 w-full">
                            {request?.created_by_plan_name && request?.created_by_plan_name_pt &&
                                <p className="font-bold">
                                    {currentLanguage() === 'en' ? request?.created_by_plan_name : request?.created_by_plan_name_pt }
                                </p>}
                            { request?.show_phone == true &&
                                <button onClick={() => { handleShowPhone() }} className="text-lg">
                                    <FaPhoneAlt />
                                </button>
                            }
                            {request?.created_by !== auth?.id && (
								<button
									onClick={() => createChat(request?.id)}
									className="text-lg"
								>
									<BsFillChatLeftFill />
								</button>
							)}

                        </div>
                        {showPhone &&
                            <p className="text-primary text-base">{request?.created_by_phone}</p>
                        }
                    </div>
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <div className="w-16 h-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request?.created_by_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                {/* LAPTOP */}
                <div className={`flex 2xl:hidden items-center justify-center gap-x-5 col-span-1 ${ unauth && 'blur-sm' }`}>
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <h1 className="font-bold text-lg">
                            {request?.created_by_name}
                        </h1>                            
                        <div className="w-16 h-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request?.created_by_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                        <div className="space-y-2">
                            {(request?.company_id != null && !unauth) && 
                                <div className="h-14 mx-auto">
                                    <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${request?.company_logo}`} alt={request?.company_name} className="w-full h-full object-contain" />
                                </div>
                            }
                            {(request?.is_individual && !unauth) && 
                                <img src={request?.individual_logo} alt="Particular" className="h-8 object-contain" />
                            }
                            <div className="flex text-primary items-center justify-end gap-x-4 w-full">
                                {request?.created_by_plan_name && request?.created_by_plan_name_pt &&
                                    <p className="font-bold">
                                        {currentLanguage() === 'en' ? request?.created_by_plan_name : request?.created_by_plan_name_pt }
                                    </p>
                                }
                                { request?.show_phone == true &&
                                    <button onClick={() => { handleShowPhone() }} className="text-lg">
                                        <FaPhoneAlt />
                                    </button>
                                }
                                {request?.created_by !== auth?.id && (
                                    <button
                                        onClick={() => createChat(request?.id)}
                                        className="text-lg"
                                    >
                                        <BsFillChatLeftFill />
                                    </button>
                                )}

                            </div>
                            {showPhone &&
                                <p className="text-primary text-base">{request?.created_by_phone}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestCard;
